import React from 'react'
import Lottie from "react-lottie";
import animationData from './assets/loader_lottie_animation.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export const LoaderAnimation = () => (
    <div className="loader-wrapper">
        <Lottie options={defaultOptions} width="100%" />
    </div>
)