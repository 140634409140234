import React, { useCallback, useContext, useEffect, useState } from 'react';
import 'sendbird-uikit/dist/index.css';
import useMinimize from '../hooks/useMinimize';
import ActiveCallButtons from './ActiveCallButtons';
import ActivePhoneCall from './ActivePhoneCall';
import ActiveVideoCall from './ActiveVideoCall';
import { dialingTone, getLocalMediaView, getRemoteMediaView, ringingTone } from './common';
import { EventTypes, createLagunaEvent } from '../common/utils';
import './common.css';
import Ringing from './Ringing';
import { CallContext } from '../context/CallContext';

const BaseCaller = ({ me, callee, showCallButtons, onCallEnded, dial, call, accept, hangup, sendChatMessage }) => {
  const callEventsContext = useContext(CallContext);
  const [activeCall, setActiveCall] = useState(null);
  const [isRinging, setIsRinging] = useState(false);
  const [isOutgoing, setIsOutgoing] = useState(false);
  const { isMinimized, setIsMinimized } = useMinimize();

  const init = () => {
    setCall(call);
    if (activeCall) {
      return;
    }
    if (isOutgoing) {
      dialingTone.loop = true;
      dialingTone.play();
    } else {
      setIsRinging(true);
      ringingTone.loop = true;
      ringingTone.play();
    }
  };

  const _dial = useCallback(
    (isVideo) => {
      setIsOutgoing(true);
      dial(isVideo);
    },
    [dial]
  );

  const _accept = () => {
    accept(activeCall);
    setIsRinging(false);
  };

  const cleanup = () => {
    callEventsContext.onCallEnd();
    setCall(null);
    setIsRinging(false);
    setIsMinimized(false);
    dialingTone.pause();
    ringingTone.pause();
  };

  const setCall = (call) => {
    if (call) eventRegister(call);
    setActiveCall(call);
  };

  const eventRegister = (call) => {
    call.onEnded = (reason, fromRemote) => {
      try {
        onCallEnded(reason, fromRemote);
      } catch (error) {
        console.log('onCallEnded failed');
      }
      cleanup();
    };

    call.onConnected = () => {
      getLocalMediaView().play();
      dialingTone.pause();
      ringingTone.pause();
      const remoteElement = getRemoteMediaView();
      const originalCallback = remoteElement.onloadeddata;
      remoteElement.onloadeddata = () => {
        const localStream = getLocalMediaView().srcObject;
        const remoteStream = remoteElement.srcObject;
        callEventsContext.onCallStart({
          type: call._isVideoCall ? 'video' : 'audio',
          localStream,
          remoteStream,
          callId: call._callId,
        });

        if (originalCallback) {
          originalCallback();
        }
        remoteElement.onloadeddata = originalCallback;
      };
    };
  };

  const isVideoVisible = () => {
    return !!activeCall && !isRinging && activeCall?.isVideoCall;
  };

  const renderCall = () => {
    if (isRinging)
      return <Ringing isVideoCall={activeCall?.isVideoCall} onAccept={_accept} onHangup={hangup} callee={callee} />;
    if (activeCall?.isVideoCall === false && !isMinimized)
      return <ActivePhoneCall activeCall={activeCall} callee={callee} />;

    return null;
  };

  const showBackToCallButton = activeCall?.isVideoCall === false && isMinimized;

  useEffect(() => {
    if (call) init(call);
  }, [call]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    callEventsContext.updateController({
      onVideoCall: !showBackToCallButton && showCallButtons ? () => _dial(true) : null,
      onCall: !showBackToCallButton & showCallButtons ? () => _dial(false) : null,
      backToCall: showBackToCallButton ? () => setIsMinimized(false) : null,
      sendChatMessage,
    });
  }, [showBackToCallButton, showCallButtons, _dial]);

  return (
    <>
      {renderCall()}
      <div className={isVideoVisible() ? 'visible' : 'hidden'}>
        <ActiveVideoCall me={me} callee={callee} activeCall={activeCall} />
        {!isMinimized && <ActiveCallButtons activeCall={activeCall} />}
      </div>
    </>
  );
};
export default BaseCaller;
