import { useEffect, useRef } from 'react';

export function useDisconnectOnUnmount(sdk) {
  const sdkRef = useRef(sdk);

  useEffect(() => {
    sdkRef.current = sdk;
  }, [sdk]);

  useEffect(() => {
    return () => {
      try {
        sdkRef.current.disconnect();
      } catch (e) {}
    };
  }, []);
}
