import HumanizeDuration from 'humanize-duration';
import React, { useCallback, useState } from 'react';
import SendBirdCall from 'sendbird-calls';
import 'sendbird-uikit/dist/index.css';
import useSendbirdCalls from '../hooks/useSendbirdCalls';
import BaseCaller from './BaseCaller';
import { getLocalMediaView, getRemoteMediaView } from './common';
import './common.css';

const SendbirdCaller = ({ appId, me, callee, showCallButtons, sendChatMessage, userToken, onVideoChatEvent }) => {
  const [activeCall, setActiveCall] = useState();
  const userId = me.userId;

  useSendbirdCalls(appId, userToken, userId, (call) => {
    setActiveCall(call);
  });

  const getCallOption = useCallback(
    (isVideoCall) => ({
      callOption: {
        localMediaView: getLocalMediaView(),
        remoteMediaView: getRemoteMediaView(),
        audioEnabled: true,
        videoEnabled: isVideoCall,
      },
    }),
    []
  );

  const accept = (call) => {
    const acceptParams = getCallOption(true);
    call.accept(acceptParams);
  };

  const hangup = () => {
    activeCall.end();
  };

  const dial = useCallback(
    (isVideoCall) => {
      SendBirdCall.dial(
        {
          userId: callee.userId,
          isVideoCall,
          ...getCallOption(isVideoCall),
        },
        (call, error) => {
          if (error) {
            console.log(error);
            alert('user is not logged in');
          } else {
            setActiveCall(call);
          }
        }
      );
    },
    [getCallOption, callee, setActiveCall]
  );

  const onCallEnded = () => {
    activeCall.end();
    if (activeCall.isCaller && sendChatMessage) {
      const message = `call ${activeCall.endResult} ${
        activeCall?.callLog?.duration > 0
          ? HumanizeDuration(activeCall.callLog.duration, {
              units: ['m', 's'],
              round: true,
              maxDecimalPoints: 2,
            })
          : ''
      }`;
      sendChatMessage(message, 'call-status');
    }
  };

  return (
    <BaseCaller
      accept={accept}
      hangup={hangup}
      showCallButtons={showCallButtons}
      onCallEnded={onCallEnded}
      dial={dial}
      call={activeCall}
      me={me}
      callee={callee}
    />
  );
};
export default SendbirdCaller;
