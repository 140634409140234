import React, { useEffect, useState, useContext } from 'react';
import cc from 'classcat';
import { Channel, ChannelSettings } from 'sendbird-uikit';
import Sendbird from 'sendbird';
import Avatar from './common/Avatar';
import Article from './common/Article';
import { CallContext } from './context/CallContext';
import { createLagunaEvent, EventTypes, isValidURL } from './common/utils';
import file from './download.svg';
import noMessages from './assets/noMessages.svg';
import noMessagesDark from './assets/noMessagesDark.svg';
import { JournalChatEntry } from './JournalChatEntry';
import './Chat.scss';
import 'sendbird-uikit/dist/index.css';

const CONTENT_CATEGORY = {
  JOURNAL: 'journal',
};

const CUSTOM_TYPE = {
  CHAT: 'chat',
  SMS: 'textSms',
  ARTICLE: 'article',
  JOURNAL_CONTENT: 'journalContent',
  CALL_STATUS: 'call-status',
};

const getMessageMetaData = (message) => {
  try {
    return JSON.parse(message.data);
  } catch (e) {
    return {};
  }
};

const replaceEmptyMessagesIfExists = (theme) => {
  const noMessageSelector = '.sendbird-conversation__no-messages';
  const noMessageIconSelector = '.sendbird-place-holder__body';
  if (!document.querySelector(`${noMessageSelector} ${noMessageIconSelector}`)) {
    return;
  }
  document.querySelector(`${noMessageSelector} ${noMessageIconSelector}`).remove();
  const node = document.createElement('div');
  node.innerHTML = `
      <div class="missing-text ${theme === 'dark' ? 'dark' : 'light'}">
        <img src="${theme === 'dark' ? noMessagesDark : noMessages}"/>
        <div>No messages</div>
      </div>
      `;
  document.querySelector(noMessageSelector).classList.add('visible');
  document.querySelector(noMessageSelector).appendChild(node);
};

const addAcceptToSendBirdSendButton = (theme) => {
  document
    .getElementsByClassName('sendbird-message-input--attach-input')[0]
    ?.setAttribute('accept', 'image/*,video/*,audio/*,application/pdf');
  replaceEmptyMessagesIfExists(theme);
};

const checkIfNotificationExists = () => {
  const notificationsElement = document.getElementsByClassName('sendbird-notification')[0];
  if (notificationsElement) {
    notificationsElement.addEventListener('click', () => {
      setTimeout(notificationsElement?.remove, 1000);
    });
  }
};

const isAdmin = ({ messageType }) => messageType === 'admin';
const isSms = ({ customType, messageType }) =>
  customType === CUSTOM_TYPE.SMS || (messageType === 'admin' && customType === CUSTOM_TYPE.CHAT);
const isArticle = ({ customType }) => customType === CUSTOM_TYPE.ARTICLE;
const isJournal = ({ customType, data }) =>
  customType === CUSTOM_TYPE.JOURNAL_CONTENT ||
  getMessageMetaData({ data }).contentCategory === CONTENT_CATEGORY.JOURNAL;

const getMessageStyle = (message, userId) => {
  if (message.customType === CUSTOM_TYPE.CALL_STATUS) {
    return 'call-status';
  }
  const getIsOutgoing = () => {
    if (isSms(message) || isAdmin(message)) {
      const messageSenderId = getMessageMetaData(message).senderId;
      return isSms(message) ? messageSenderId === userId : messageSenderId !== userId;
    }
    return !message?.sender?.userId || message?.sender?.userId === userId;
  };
  const isOutGoing = getIsOutgoing();
  return cc({
    message: true,
    journal: isJournal(message),
    'sms-message': isSms(message),
    'outgoing-message': isOutGoing,
    'incoming-message': !isOutGoing,
  });
};

const getMessageText = (message) => {
  const text = message.message;
  return (
    <span className='message-text'>
      {isValidURL(text) ? (
        <a href={text} target='_blank' rel='noopener noreferrer'>
          {text}
        </a>
      ) : (
        text
      )}
    </span>
  );
};

const shouldFilterMessage = (message) => {
  return message?.type?.startsWith('text/');
};

const renderMessageContent = (message) => {
  if (isJournal(message)) {
    return <JournalChatEntry message={message} />;
  } else if (message.messageType === 'file') {
    if (message.type.startsWith('image/')) {
      return <img className='file-image' src={message.url} />;
    }
    if (message.type.startsWith('audio/')) {
      return <audio preload='none' className='file-audio' src={message.url} controls />;
    }
    if (message.type.startsWith('video/')) {
      return <video preload='none' className='file-image' src={message.url} controls />;
    }
    if (message.type === 'application/pdf') {
      return (
        <a className='file-download' href={message.url} download='proposed_file_name'>
          <img src={file} />
          <span>{message.name}</span>
        </a>
      );
    }
    return getMessageText(message);
  } else {
    return getMessageText(message);
  }
};

const renderCustomMessage = (message, userId) => {
  if (message?.type?.startsWith('image/') && !isJournal(message)) return;
  if (shouldFilterMessage(message)) return;

  const time = new Date(message.createdAt).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const ViaElement = () => {
    const isMessage = isSms(message);
    const isJournalEntry = isJournal(message);
    if (!isMessage && !isJournalEntry) {
      return null;
    }
    return <span className='via-element'>{`via ${isMessage ? 'text message' : 'Journal'}`}</span>;
  };

  return () => (
    <>
      <div className={getMessageStyle(message, userId)}>
        <div className='message-ballon'>
          {renderMessageContent(message)}
          <br />
          <span className='time'>
            {time}
            <ViaElement />
          </span>
        </div>
      </div>
      {isArticle(message) && <Article message={message} />}
    </>
  );
};

const Chat = ({ channelUrl, userId, partner, isHeadless, messageReceived, theme, onVideoChatEvent }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const { currentAppointmentContext } = useContext(CallContext);

  useEffect(() => {
    if (messageReceived) {
      setTimeout(checkIfNotificationExists, 100);
    }
  }, [messageReceived]);

  useEffect(() => {
    setTimeout(() => addAcceptToSendBirdSendButton(theme), 1000);
  }, []);

  useEffect(() => {
    const handleIncomingMessage = (event) => {
      if (event?.data) {
        const data = JSON.parse(event.data);
        if (data?.keyboardState) {
          setIsKeyboardOpen(data.keyboardState === 'open');
        }
      }
    };
    document.addEventListener('message', handleIncomingMessage, false);
    return () => {
      document.removeEventListener('message', handleIncomingMessage, false);
    };
  }, []);

  const focusInput = (text) => {
    const { UserMessageParams } = Sendbird.getInstance();
    if (onVideoChatEvent) onVideoChatEvent(createLagunaEvent({ action: EventTypes.messageSent }));

    const params = new UserMessageParams();
    params.message = text;
    params.data = JSON.stringify({ appointmentId: currentAppointmentContext });
    if (isKeyboardOpen) {
      const inputElement = document.getElementsByName('sendbird-message-input')[0];
      inputElement.focus();
    }
    return params;
  };

  return (
    <>
      <Channel
        channelUrl={channelUrl}
        onChatHeaderActionClick={() => setIsSettingsOpen(!isSettingsOpen)}
        onBeforeSendUserMessage={focusInput}
        renderCustomMessage={(message) => renderCustomMessage(message, userId)}
        renderChatHeader={() => <ChatHeader isHeadless={isHeadless} partner={partner} theme={theme} />}
      />
      {isSettingsOpen && <ChannelSettings channelUrl={channelUrl} onCloseClick={() => setIsSettingsOpen(false)} />}
    </>
  );
};

const ChatHeader = ({ isHeadless, partner, theme }) => {
  const getStatusMessage = () => {
    if (partner?.isTyping) return 'Typing...';
    return partner?.connectionStatus || 'Offline';
  };

  if (isHeadless) return <div className='header'></div>;

  return (
    <div className={cc(['chat-header', { dark: theme === 'dark' }])}>
      <div className='chat-avatar-wrapper'>
        <Avatar className='chat-avatar' alt='partner img' user={partner} />
      </div>
      <div>
        <div className='bold'>{partner?.nickname}</div>
        <div className='small-text'>{getStatusMessage()}</div>
      </div>
    </div>
  );
};

export default Chat;
