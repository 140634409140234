const ua = window.navigator.userAgent;
const isIOS = () => {
  return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
};

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isIOSButNotSafari = () => {
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = isIOS() && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);
  return isIOS() && !iOSSafari;
};

const getURLParams = () => {
  try {
    var search = document.location.search.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  } catch (e) {
    console.log('invalid url');
  }
  return {};
};
const setWindowFullHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const isCoach = (role) => role === 'coach';

const isCoachUser = (user) => isCoach(user?.metaData?.role);

const EventTypes = {
  appLoaded: 'appLoaded',
  incomingMessage: 'incomingMessage',
  calling: 'calling',
  messageSent: 'messageSent',
};
const sendMessageToParent = (message) => {
  window.parent.postMessage(
    {
      baseType: 'laguna-message',
      ...message,
    },
    '*'
  );
};

const createLagunaEvent = (message) => ({
  data: {
    baseType: 'laguna-message',
    ...message,
  },
});

const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const noop = () => {};

function isValidURL(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
export {
  isIOS,
  isIOSButNotSafari,
  getURLParams,
  isSafari,
  setWindowFullHeight,
  isCoach,
  isCoachUser,
  sendMessageToParent,
  EventTypes,
  isEmpty,
  createLagunaEvent,
  noop,
  isValidURL,
};
