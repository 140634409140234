import React, { useState } from 'react';
import { noop } from '../common/utils';

export const CallContext = React.createContext({});

export default function CallProvider({
  children = noop,
  onCallEnd = noop,
  onCallStart = noop,
  onOutOfChatMessageReceived = noop,
  onExternalControllerUpdate = noop,
  currentAppointmentContext = '',
}) {
  const [callExtraInfo, setCallExtraInfo] = useState({});
  const [, setController] = useState({
    onVideoCall: noop,
    onCall: noop,
    backToCall: noop,
  });
  const updateController = (newController) => {
    onExternalControllerUpdate(newController);
    setController(newController);
  };
  return (
    <CallContext.Provider
      value={{
        callExtraInfo,
        setCallExtraInfo,
        onCallEnd,
        onCallStart,
        onOutOfChatMessageReceived,
        updateController,
        currentAppointmentContext,
      }}>
      {children}
    </CallContext.Provider>
  );
}
