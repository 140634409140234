import { useContext } from 'react';
import { CallContext } from '../context/CallContext';

const useCallExtraInfo = () => {
  const { callExtraInfo, setCallExtraInfo } = useContext(CallContext);

  const appendCallExtraInfo = (data) => {
    if (callExtraInfo) setCallExtraInfo({ ...callExtraInfo, ...data });
  };

  return {
    callExtraInfo,
    setCallExtraInfo,
    appendCallExtraInfo,
  };
};
export default useCallExtraInfo;
