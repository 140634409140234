import React, { useEffect, useState } from 'react';

import './common.css';
import useCallExtraInfo from '../hooks/useCallExtraInfo';
import BaseVideo from './BaseVideo';
import useMinimize from '../hooks/useMinimize';

const LocalVideo = ({ user, id }) => {
  const [isVideoActive, setIsVideoActive] = useState();

  const { callExtraInfo } = useCallExtraInfo();
  const { isMinimized } = useMinimize();

  const getWrapperClasses = () => `video-wrapper local ${isMinimized ? 'minimized-local' : 'minimized'}`;

  useEffect(() => {
    setIsVideoActive(callExtraInfo?.isLocalVideoEnabled);
  }, [callExtraInfo?.isLocalVideoEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={getWrapperClasses()}>
      <BaseVideo user={user} isVideoActive={isVideoActive} id={id} muted />
    </div>
  );
};
export default LocalVideo;
