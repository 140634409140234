import React from 'react';

export const JournalChatEntry = React.memo(
  ({ message }) => {
    const messageText = JSON.parse(message.data)?.message;
    const getContent = () => {
      const { url } = message;
      if (!url) return null;
      return message.type.startsWith('audio/') ? (
        <audio src={url} controls preload='none' className='file-audio' />
      ) : (
        <img src={url} />
      );
    };
    return (
      <div className='journal-entry'>
        {getContent()}
        {messageText && <p>{messageText}</p>}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps?.message.messageId === nextProps?.message.messageId
);
