import React, { useEffect, useState } from 'react';
import 'sendbird-uikit/dist/index.css';
import Expired from './assets/expired.svg';
import team from './assets/team.png';
import LagunaCaller from './calls/LagunaCaller';
import SendbirdCaller from './calls/SendbirdCaller';
import Chat from './Chat';
import { EventTypes, isCoachUser, isSafari, sendMessageToParent, createLagunaEvent } from './common/utils';
import Error from './Error';
import './Home.css';
import { useChannelInfo } from './hooks/useChannelInfo';
import { useChatEvents } from './hooks/useChatEvents';
import { useDisconnectOnUnmount } from './hooks/useDisconnectOnUnmount';
import { LoaderAnimation } from './LoaderAnimation';
import Standby from './Standby';

const Home = ({
  sdk,
  appId,
  userId,
  channelUrl,
  sendMessage,
  isHeadless,
  userToken,
  isCallActive,
  onVideoChatEvent,
  theme,
}) => {
  const [channelInfo, error, isLoading, nextAppointment, me] = useChannelInfo(sdk, channelUrl, userId, userToken);
  const [isTyping, messageReceived, partner] = useChatEvents(sdk, channelInfo);
  const [showStandby, setShowStandby] = useState(false);
  const [isSafariUsed, setIsSafariUsed] = useState(isSafari());

  const isWebMode = () => channelInfo?.isFrozen;
  const _send = async (message, type, data) => {
    const params = new sdk.UserMessageParams();
    params.message = message;
    params.customType = type;
    params.data = data || '';
    return sendMessage(channelUrl, params);
  };

  useDisconnectOnUnmount(sdk);

  useEffect(() => {
    if (!isLoading && !error) {
      setShowStandby(isWebMode() && !!nextAppointment && !isCoachUser(me));
    }
  }, [isLoading, channelInfo, channelUrl, nextAppointment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading) {
      sendMessageToParent({ type: EventTypes.appLoaded });
      if (onVideoChatEvent) {
        onVideoChatEvent(createLagunaEvent({ type: EventTypes.appLoaded }));
      }
    }
  }, [isLoading, onVideoChatEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (messageReceived) {
      sendMessageToParent({ action: EventTypes.incomingMessage });
      if (onVideoChatEvent) {
        onVideoChatEvent(createLagunaEvent({ action: EventTypes.incomingMessage }));
      }
    }
  }, [messageReceived, onVideoChatEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error && !isLoading) return <Error img={Expired} alt='expired' text="This appointment isn't active anymore" />;

  if (isSafariUsed)
    return (
      <div className='fullScreenPage center'>
        <img src={team} alt='team' width='300px' />
        <div className='start-session-button' onClick={() => setIsSafariUsed(false)}>
          Start my session
        </div>
      </div>
    );

  if (isLoading)
    return (
      <div className='fullScreenPage center'>
        <LoaderAnimation />
      </div>
    );

  const getCallerBaseProps = {
    me,
    callee: partner,
    showCallButtons: isCoachUser(me),
    sendChatMessage: isWebMode() ? null : _send,
  };

  return (
    <>
      {showStandby && <Standby callee={partner} user={me.nickname} nextAppointment={nextAppointment} />}
      {isWebMode() ? (
        <SendbirdCaller appId={appId} userToken={userToken} {...getCallerBaseProps} />
      ) : (
        <LagunaCaller
          {...getCallerBaseProps}
          messageReceived={messageReceived}
          isCallActive={isCallActive}
          onVideoChatEvent={onVideoChatEvent}
        />
      )}
      {(!isWebMode() || isCoachUser(me)) && (
        <Chat
          userId={userId}
          messageReceived={messageReceived}
          channelUrl={channelUrl}
          isHeadless={isHeadless}
          partner={{ ...partner, isTyping }}
          theme={theme}
          onVideoChatEvent={onVideoChatEvent}
        />
      )}
    </>
  );
};
export default React.memo(Home);
