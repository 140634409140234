import { useEffect, useRef, useState } from 'react';
import { isCoachUser, isEmpty, makeCancelable } from '../common/utils';

const APPOINTMENT_DURATION = 30 * 60000;
const METADATA_KEY = 'appointments';

const getMyInfoFromChannel = (channelInfo, myId) => {
  return channelInfo.memberMap[myId];
};

const filterOldAppointments = (appointment) => {
  const isInTheFeature =
    appointment?.start && new Date(appointment?.start).getTime() + APPOINTMENT_DURATION > new Date().getTime();
  const wrongStatus = appointment?.status !== 'scheduled';
  return isInTheFeature && !wrongStatus;
};

const NO_APPOINTMENTS_MESSAGE = 'no upcoming appointments';

export const useChannelInfo = (sdk, channelUrl, userId, accessToken) => {
  const [memo, setMemo] = useState();
  const [error, setError] = useState();
  const [me, setMe] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [nextAppointment, setNextAppointment] = useState();

  const setChannelInfo = (channelInfo) => {
    setMe(getMyInfoFromChannel(channelInfo, sdk.getCurrentUserId()));
    setMemo(channelInfo);
  };

  const setErrorMode = (type) => {
    setError(type);
    setIsLoading(false);
  };

  const calcNextAppointment = (appointments) => {
    const now = new Date().getTime();
    const orderedAppointments = appointments.sort((a) => Math.abs(new Date(a?.start).getTime() - now));
    if (!orderedAppointments.length) {
      setErrorMode(NO_APPOINTMENTS_MESSAGE);
      return;
    }
    const appointment = orderedAppointments[0];
    const appointmentTime = new Date(appointment?.start).getTime();
    const diff = appointmentTime - now;
    const isInThePast = now > appointmentTime;
    if (isInThePast && diff * -1 > APPOINTMENT_DURATION) {
      setError(NO_APPOINTMENTS_MESSAGE);
    } else {
      setNextAppointment({ ...appointment, diff });
    }
    return appointment;
  };

  const handleWebMode = (channel) => {
    const { data } = channel || {};
    if (!data) {
      setErrorMode(NO_APPOINTMENTS_MESSAGE);
      return;
    }
    const metadata = JSON.parse(data);
    const appointmentsObj = metadata[METADATA_KEY];
    if (isEmpty(appointmentsObj)) {
      setErrorMode(NO_APPOINTMENTS_MESSAGE);
      return;
    }
    const appointments = Object.values(appointmentsObj).filter(filterOldAppointments);
    if (!appointments.length) {
      console.log('no appointment with status scheduled in the feature ');
      setErrorMode(NO_APPOINTMENTS_MESSAGE);
      setChannelInfo(channel);
      return;
    }

    calcNextAppointment(appointments);
    setIsLoading(false);
  };

  const shouldUseWebMode = (channel) => {
    const me = getMyInfoFromChannel(channel, sdk.getCurrentUserId());
    return !isCoachUser(me) && channel?.isFrozen;
  };

  const getChannelInfo = async () => {
    setIsLoading(true);

    try {
      // Making sure sdk is open before interacting with it to prevent "Connection should be made first" exception
      if (sdk.getConnectionState() !== sdk.ConnectionState.OPEN) {
        await sdk.connect(userId, accessToken);
      }
      // Another ugly hack to prevent sb "ConversationScroll" from throwing exceptions on unmount
      sdk.getAllEmoji = () => {};
      sdk.GroupChannel.getChannel(channelUrl, (channel, error) => {
        if (channel) {
          // Ugly hack to fix command-received-no-ack exception
          // https://community.sendbird.com/t/sendbirdexception-command-received-no-ack/1925/9
          channel.markAsRead = () => {};
        }
        if (error) {
          console.log('not in group', error);
          setErrorMode('not in group');
        } else if (shouldUseWebMode(channel)) {
          setChannelInfo(channel);
          handleWebMode(channel);
        } else {
          setChannelInfo(channel);
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log('fail to get cannel info', e);
      setErrorMode('error');
    }
  };

  useEffect(() => {
    if (sdk && sdk.GroupChannel && channelUrl) getChannelInfo();
  }, [sdk, channelUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return [memo, error, isLoading, nextAppointment, me];
};
