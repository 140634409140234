import useCallExtraInfo from './useCallExtraInfo';

const useMinimize = () => {
  const { callExtraInfo, appendCallExtraInfo } = useCallExtraInfo();
  return {
    isMinimized: callExtraInfo?.isMinimized,
    setIsMinimized: (val) => appendCallExtraInfo({ isMinimized: val }),
  };
};
export default useMinimize;
