import React from 'react';
import './Article.scss';
import AudioIcon from '../assets/icon_article_audio.png';
import ArticleIcon from '../assets/icon_article_default.png';
import VideoIcon from '../assets/icon_article_video.png';
import ClockIcon from '../assets/icon_clock.png';

const getParsedJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return false;
  }
};

const getLink = (article) => `laguna://explore/${article.id}`;

const getContentType = (article) => {
  switch (article.type) {
    case 'Audio':
      return (
        <>
          <img src={AudioIcon} /> Audio
        </>
      );
    case 'Video':
      return (
        <>
          <img src={VideoIcon} /> Video
        </>
      );
    case 'Article':
    default:
      return (
        <>
          <img src={ArticleIcon} /> Article
        </>
      );
  }
};

const getDuration = (article) => {
  if (!article.duration) return null;
  return (
    <>
      &#8195;&#8195;•&#8195;&#8195;
      <img src={ClockIcon} />
      {new Date(article.duration * 1000).toISOString().substr(14, 5)}
    </>
  );
};

// NOTE: Articles are sent via SendArticleModal in Harmony.
// message.data is a stringified JSON with keys of:
// id / title / smallImageUrl / type / duration?

const Article = ({ message }) => {
  const article = getParsedJson(message.data);

  if (!article) return null;

  return (
    <a href={getLink(article)} className='article'>
      <img className='cover' src={article.smallImageUrl} alt={article.title} />
      <div className='description'>
        <p>{article.title}</p>
        <div className='type'>
          {getContentType(article)}
          {getDuration(article)}
        </div>
      </div>
    </a>
  );
};

export default Article;
