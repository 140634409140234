import React from 'react';
import './Standby.css';

const MINUTE = 60000;
const HOUR = 60 * MINUTE;

const Standby = ({ user, callee, nextAppointment }) => {
  const getText = () => {
    const diff = nextAppointment?.diff;
    let message = 'moment';
    if (diff > HOUR) {
      const diffText = new Date(nextAppointment?.start).toLocaleString();
      return `your appointment is set for ${diffText}`;
    } else if (diff <= HOUR && diff >= MINUTE) {
      const diffInMinutes = Math.round(diff / MINUTE);
      message = `${diffInMinutes} minutes`;
    }

    return `${callee.nickname} will join you here in a ${message}, meeting will start
      automatically.`;
  };
  return (
    <div className='fullScreenPage center'>
      <div className='row'>
        <img className='avatar' src={callee.profileUrl} alt='img' />
      </div>
      <h1>Hi {user}</h1>
      <div className='wait-text'>{getText()}</div>
    </div>
  );
};

export default Standby;
