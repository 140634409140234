import Peer from 'peerjs';
import React from 'react';
import { EventTypes, sendMessageToParent, createLagunaEvent } from '../common/utils';
import BaseCaller from './BaseCaller';
import { getLocalMediaView, getRemoteMediaView } from './common';

const PeerMessageTypes = {
  videoState: 'videoState',
  micState: 'micState',
  close: 'close',
  pendingReconnect: 'pendingReconnect',
};

const toggleTracks = (state, tracks) => {
  if (tracks)
    tracks.forEach((track) => {
      track.enabled = state;
    });
};

class LagunaCaller extends React.Component {
  state = { callClosed: false };

  componentDidUpdate(prevProps) {
    if (prevProps.isCallActive === true && this.props.isCallActive === false) {
      this.state.activeCall.onEnded();
    }
    const { callClosed, localStream } = this.state;
    if (callClosed && localStream) {
      this.cleanStreamData();
    }
  }

  sendMessage = (message, fromRemote) => {
    const { peerConnection } = this.state;
    if (peerConnection) peerConnection.send(message);
    if (!peerConnection || fromRemote) {
      sendMessageToParent(message);
      this.props.onVideoChatEvent(createLagunaEvent(message));
    }
  };

  appendToActiveCall = (data) => {
    this.setState((prev) => ({ activeCall: { ...prev.activeCall, ...data } }));
  };

  toggleMicrophone = (state) => () => {
    const tracks = this.state.localStream?.getAudioTracks();
    toggleTracks(state, tracks);
    this.sendMessage({ type: PeerMessageTypes.micState, value: state });
  };

  toggleVideo = (state) => () => {
    const tracks = this.state.localStream?.getVideoTracks();
    toggleTracks(state, tracks);
    this.appendToActiveCall({ isLocalVideoEnabled: state });
    this.sendMessage({ type: PeerMessageTypes.videoState, value: state });
  };

  getLocalSteam = (isVideo) => {
    navigator.getUserMedia(
      { video: isVideo, audio: true },
      (localStream) => {
        this.setState({ localStream });
        getLocalMediaView().srcObject = localStream;
      },
      function (err) {
        console.log('Failed to get local stream', err);
      }
    );
  };

  initActiveCall = (isVideo) => {
    const activeCall = {
      unmuteMicrophone: this.toggleMicrophone(true),
      muteMicrophone: this.toggleMicrophone(false),
      stopVideo: this.toggleVideo(false),
      startVideo: this.toggleVideo(true),
      isVideoCall: isVideo,
      isLocalVideoEnabled: isVideo,
      isRemoteVideoEnabled: false,
    };
    this.setState({ activeCall });
  };

  incomingDataChannel = (data) => {
    //TODO: handle all events and test them
    const { type } = data;
    switch (type) {
      case PeerMessageTypes.close:
        this.state.activeCall.onEnded(data.reason, true);
        break;
      case PeerMessageTypes.videoState:
        this.appendToActiveCall({ isRemoteVideoEnabled: data.value });
        break;
      case PeerMessageTypes.pendingReconnect:
        this.callToPeer(this.state.peerConnection);
        break;
    }
  };

  callToPeer = (conn) => {
    const call = this.state.peer.call(conn.peer, this.state.localStream);
    call.on('stream', (remoteStream) => {
      this.appendToActiveCall({ isRemoteVideoEnabled: true });
      getRemoteMediaView().srcObject = remoteStream;
    });
  };

  onNewPeerConnection = (conn) => {
    this.state.activeCall.onConnected();
    this.setState({ peerConnection: conn });
    conn.peerConnection.onconnectionstatechange = () => {
      conn.on('data', this.incomingDataChannel);
      conn.peerConnection.onconnectionstatechange = null;
    };
    this.callToPeer(conn);
  };

  createPeer = (peer) => {
    this.setState({ peer });
    //TODO: handle disconnected/reconnect
    peer.on('disconnected', console.log);
    peer.on('close', () => {
      peer.destroy();
      peer = null;
    });
    peer.on('connection', this.onNewPeerConnection);
  };

  initPeer = (isVideo) => {
    //TODO:get uuid
    const peerId = new Date().getTime().toString();
    this.createPeer(new Peer(peerId));
    sendMessageToParent({ type: EventTypes.calling, isVideo, peerId });
    this.props.onVideoChatEvent(createLagunaEvent({ type: EventTypes.calling, isVideo, peerId }));
    this.initActiveCall(isVideo);
    this.getLocalSteam(isVideo);
  };

  //this event is not used cause laguna caller is used only for the coach side
  // hangup is fired when user decline a call
  hangup = () => {
    const { peer, activeCall } = this.state;
    this.sendMessage({ type: PeerMessageTypes.close, peerId: peer.id });
    activeCall.onEnded();
  };

  //this event is not used cause laguna caller is used only for the coach side
  // accept is fired when user accept a call
  accept = () => {
    this.sendMessage({
      type: PeerMessageTypes.accept,
      peerId: this.state.peer.id,
    });
  };

  cleanStreamData = () => {
    const { localStream } = this.state;
    localStream.getTracks().forEach((track) => {
      track.stop();
    });
    getRemoteMediaView().srcObject = null;
    this.setState({ callClosed: false });
  };

  onCallEnded = (reason, fromRemote) => {
    const { localStream, peer, peerConnection } = this.state;
    if (localStream) {
      this.cleanStreamData();
    }
    this.sendMessage({ type: PeerMessageTypes.close, peerId: peer.id, reason }, fromRemote);
    if (peerConnection) peerConnection.close();
    peer.disconnect();
    peer.destroy();
    this.setState({
      callClosed: true,
      peer: null,
      peerConnection: null,
    });
  };

  render() {
    const { me, callee, showCallButtons, sendChatMessage } = this.props;
    return (
      <BaseCaller
        accept={this.accept}
        hangup={this.hangup}
        showCallButtons={showCallButtons}
        onCallEnded={this.onCallEnded}
        dial={this.initPeer}
        call={this.state?.activeCall}
        me={me}
        callee={callee}
        sendChatMessage={sendChatMessage}
      />
    );
  }
}

export default LagunaCaller;
